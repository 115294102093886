import clsx from 'clsx';
import { useCallback, useMemo, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { BotaoComTexto } from '@digix-ui/componentes';
import DialogoAlterarSenha from 'components/DialogoAlterarSenha';

import logoEProcon from 'assets/icons/logo-claro.svg';
import { useAuth } from 'hooks';
import { termoDePrivacidade, termoDeUso } from 'services/termos';

import classes from './styles.module.scss';
import { LayoutMenuItem } from './types';

export function AppLayout(): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();
  const { nome, ehAdmin } = useAuth();
  const [dialogoAlterarSenha, setDialogoAlterarSenha] = useState(false);
  const handleDialogoAlterarSenha = () =>
    setDialogoAlterarSenha(!dialogoAlterarSenha);

  const { deslogar } = useAuth();

  const memoMenuItems: LayoutMenuItem[] = useMemo(() => {
    const menuItems = [
      {
        id: 'documentos',
        path: '/app/documentos',
        nome: 'Documentos',
        filtro: 'documentos',
      },
    ] as LayoutMenuItem[];

    return menuItems;
  }, []);

  const getMenuAtivo = useCallback(
    (menuItem: LayoutMenuItem) => {
      const [_, filtro] = menuItem.filtro.split('-');

      return (
        location.pathname.includes(menuItem.filtro) ||
        location.pathname.includes(filtro)
      );
    },
    [location.pathname],
  );

  const handleNavigate = useCallback(
    (path: string) => () => {
      navigate(path);
    },
    [navigate],
  );

  return (
    <>
      <header className="cabecalho-sistema">
        <div className="cabecalho-sistema__container-primario">
          <BotaoComTexto
            visual="sem-preenchimento"
            tipo="button"
            onClick={() =>
              navigate(`/app/documentos`, {
                replace: true,
              })
            }
            classesAuxiliares={classes.botaoLogo}
          >
            <img
              className={clsx('cabecalho-sistema__logo', classes.icone)}
              width="33"
              src={logoEProcon}
              alt="Logotipo do Atendimento"
            />
          </BotaoComTexto>
          <>
            <nav
              className="cabecalho-sistema__menu"
              aria-label="Menu principal"
            >
              <ul className="cabecalho-sistema__lista-de-itens">
                {memoMenuItems.map(menuItem => (
                  <li key={menuItem.id}>
                    <div
                      role="presentation"
                      className={clsx('cabecalho-sistema__item-de-menu', {
                        'cabecalho-sistema__item-de-menu_ativo':
                          getMenuAtivo(menuItem),
                      })}
                      onClick={handleNavigate(menuItem.path)}
                    >
                      {menuItem.nome}
                    </div>
                  </li>
                ))}
              </ul>
            </nav>
          </>
        </div>
        <div className="cabecalho-sistema__container-secundario">
          <nav className="cabecalho-sistema__menu" aria-label="Menu secundário">
            <ul className="cabecalho-sistema__lista-de-itens">
              <li className="dropdown dropdown_direita">
                <button
                  type="button"
                  className="cabecalho-sistema__item-de-menu"
                  aria-label="Opções para o usuário Administrador"
                  aria-haspopup="true"
                >
                  <span className="cabecalho-sistema__icone-do-usuario">
                    <i className="far fa-user" />
                  </span>
                  <span className="cabecalho-sistema__nome-do-usuario">
                    {nome}
                  </span>
                  <i className="far fa-angle-down u-margem-esquerda-pequena" />
                </button>
                <ul
                  className={clsx('dropdown__lista', classes.submenu)}
                  aria-label="Submenu"
                >
                  {ehAdmin && (
                    <>
                      <li className="dropdown__item">
                        <BotaoComTexto
                          classesAuxiliares="dropdown__link"
                          visual="sem-preenchimento"
                          onClick={() =>
                            navigate(`/app/configuracoes`, {
                              replace: true,
                            })
                          }
                          cor="terciaria"
                        >
                          <i className="fa-regular fa-gear dropdown__icone" />
                          Configurações
                        </BotaoComTexto>
                      </li>
                      <li className="dropdown__item">
                        <div>
                          <BotaoComTexto
                            classesAuxiliares="dropdown__link"
                            visual="sem-preenchimento"
                            cor="terciaria"
                            onClick={handleDialogoAlterarSenha}
                          >
                            <i className="fa-regular fa-key dropdown__icone" />
                            Alterar senha
                          </BotaoComTexto>
                        </div>
                      </li>
                    </>
                  )}

                  {!ehAdmin && (
                    <>
                      <li className="dropdown__item">
                        <div>
                          <BotaoComTexto
                            classesAuxiliares="dropdown__link"
                            visual="sem-preenchimento"
                            cor="terciaria"
                            onClick={handleDialogoAlterarSenha}
                          >
                            <i className="fa-regular fa-key dropdown__icone" />
                            Alterar senha
                          </BotaoComTexto>
                        </div>
                      </li>
                    </>
                  )}

                  <li className="dropdown__item">
                    <BotaoComTexto
                      classesAuxiliares="dropdown__link"
                      visual="sem-preenchimento"
                      onClick={termoDePrivacidade}
                      cor="terciaria"
                    >
                      <i className="fa-regular fa-file-lines dropdown__icone" />
                      Política de Privacidade
                    </BotaoComTexto>
                  </li>
                  <li className="dropdown__item">
                    <BotaoComTexto
                      classesAuxiliares="dropdown__link"
                      visual="sem-preenchimento"
                      onClick={termoDeUso}
                      cor="terciaria"
                    >
                      <i className="fa-regular fa-file-lines dropdown__icone" />
                      Termo de Uso
                    </BotaoComTexto>
                  </li>
                  <li className="dropdown__item">
                    <BotaoComTexto
                      classesAuxiliares="dropdown__link"
                      visual="sem-preenchimento"
                      onClick={deslogar}
                      cor="terciaria"
                    >
                      <i className="far fa-sign-out dropdown__icone" />
                      Sair
                    </BotaoComTexto>
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
        </div>
      </header>
      <DialogoAlterarSenha
        deveSerExibido={dialogoAlterarSenha}
        fechar={handleDialogoAlterarSenha}
      />
      <Outlet />
    </>
  );
}
