import { useMemo } from 'react';
import clsx from 'clsx';
import { CampoDeTexto, ErroNoFormulario } from '@digix-ui/componentes';

import { getObjectValueByStringPath } from 'utils';

import classes from './styles.module.scss';
import { CampoDeTextoComErroDinamicoProps } from './types';

export function CampoDeTextoComErroDinamico({
  titulo,
  name,
  form,
  field,
  disabled,
  subtitulo,
  className,
  ...rest
}: CampoDeTextoComErroDinamicoProps): JSX.Element {
  const memoError = useMemo(() => {
    if (
      form?.errors &&
      field?.name &&
      form?.touched &&
      form?.touched[field?.name]
    ) {
      return getObjectValueByStringPath(
        form?.errors || {},
        field?.name || name || '',
      );
    }

    return '';
  }, [form?.errors, form?.touched, field?.name, name]);

  return (
    <div className={classes.larguraTotal}>
      <div className={clsx('formulario', classes.labelContainer)}>
        <label
          className={clsx({
            [classes.temSubtitulo]: !!subtitulo,
            [classes.obrigatorio]: !!rest?.required,
          })}
          htmlFor={rest.id || field?.name || ''}
        >
          {titulo}
        </label>
        {subtitulo && (
          <p
            className={clsx(
              'texto texto_pequeno texto_cor-cinza',
              classes.subtitulo,
            )}
          >
            {subtitulo}
          </p>
        )}
      </div>
      <CampoDeTexto
        {...rest}
        field={{ ...field, disabled }}
        form={{}}
        {...field}
        {...(form as any)}
        className={clsx(className, {
          [classes.formulario__input_erro]: !!memoError,
        })}
      />

      {memoError && <ErroNoFormulario mensagem={memoError} />}
    </div>
  );
}
