// eslint-disable-next-line import/extensions
import { LacunaWebPKI } from 'lib/lacuna/lacuna-web-pki-2.3.2';
import { Certificado } from 'models';
import { useEffect, useState } from 'react';

const lacunaWebPKI = new LacunaWebPKI() as any;

interface BuscarCertificadoComando {
  subjectName: string;
}

interface LerCertificadoComando {
  thumbprint: string;
}

interface AssinarComRestPkiComando {
  thumbprint: string;
  token: string;
}

export interface LacunaHelperInterface {
  todosCertificados(): Certificado[];
  buscarCertificado(comando: BuscarCertificadoComando): Certificado | undefined;
  lerCertificados(comando: LerCertificadoComando): Promise<string>;
  assinarComRestPki(comando: AssinarComRestPkiComando): Promise<string>;
}

export function useLacuna(): any {
  const [inicializado, setInicializado] = useState(false);
  const [certificados, setCertificados] = useState<Certificado[]>([]);

  const onWebPkiReady = () => {
    lacunaWebPKI.listCertificates().success((certs: Certificado[]) => {
      setCertificados(
        certs.map((certificado: Certificado) => ({
          ...certificado,
          titulo: certificado.subjectName,
        })),
      );
      setInicializado(true);
    });
  };

  useEffect(() => {
    lacunaWebPKI.init({
      ready: onWebPkiReady,
    });
  }, []);

  const buscarCertificado = ({
    subjectName,
  }: BuscarCertificadoComando): Certificado | undefined => {
    return certificados.find(
      certificado => certificado.subjectName === subjectName,
    );
  };

  const lerCertificados = ({
    thumbprint,
  }: LerCertificadoComando): Promise<string> => {
    return new Promise((resolve, reject) => {
      lacunaWebPKI
        .readCertificate({ thumbprint })
        .success(resolve)
        .error(reject);
    });
  };

  const assinarComRestPki = ({
    thumbprint,
    token,
  }: AssinarComRestPkiComando): Promise<string> => {
    return new Promise((resolve, reject) => {
      lacunaWebPKI
        .signWithRestPki({ thumbprint, token })
        .success(resolve)
        .error(reject);
    });
  };

  return {
    inicializado,
    certificados,
    buscarCertificado,
    lerCertificados,
    assinarComRestPki,
  };
}
