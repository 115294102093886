import clsx from 'clsx';
import { Field, Form, Formik } from 'formik';
import { CampoDeTextoComErroDinamico } from 'components/CampoDeTextoComErroDinamico';
import { BotaoComTexto, Carregamento } from '@digix-ui/componentes';

import { initialValues } from './data';
import classes from './styles.module.scss';
import { alterarSenhaSchema } from './validador';
import { useBloc } from './bloc';

export type Tamanho =
  | 'mini'
  | 'pequeno'
  | 'medio'
  | 'grande'
  | 'gigante'
  | 'largura-total';

export interface DialogoAlterarSenhaProps {
  deveSerExibido?: boolean;
  fechar: () => void;
}

export default function DialogoAlterarSenha({
  deveSerExibido,
  fechar,
}: DialogoAlterarSenhaProps): JSX.Element {
  const { handleSubmit, loading } = useBloc(fechar);

  return (
    <div
      className={clsx('dialogo dialogo_mini', {
        dialogo_ativo: deveSerExibido,
      })}
    >
      {loading && <Carregamento telaCheia />}

      <div className="dialogo__container">
        <div className="dialogo__cabecalho">
          <h2 className="dialogo__titulo">Alterar minha senha</h2>
        </div>
        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={alterarSenhaSchema}
          validateOnBlur={false}
        >
          {({ isValid, touched, resetForm }) => (
            <Form>
              <div className={clsx(classes.formulario, 'dialogo__corpo')}>
                <Field
                  titulo="Informe sua senha atual"
                  name="senhaAtual"
                  nome="senhaAtual"
                  component={CampoDeTextoComErroDinamico}
                  tipo="password"
                />
                <div>
                  <label className="formulario__label">
                    Crie uma nova senha
                  </label>
                  <p className={classes.senhaDescricao}>
                    Sua senha precisa ter pelo menos 8 caracteres
                  </p>
                  <Field
                    name="novaSenha"
                    nome="novaSenha"
                    component={CampoDeTextoComErroDinamico}
                    tipo="password"
                  />
                </div>
                <Field
                  titulo="Repita sua nova senha"
                  name="repetirNovaSenha"
                  nome="repetirNovaSenha"
                  component={CampoDeTextoComErroDinamico}
                  tipo="password"
                />
              </div>
              <div className="dialogo__rodape">
                <BotaoComTexto
                  habilitado={isValid && !!Object.keys(touched).length}
                  tipo="submit"
                  cor="primaria"
                >
                  Salvar
                </BotaoComTexto>
                <BotaoComTexto
                  tipo="button"
                  cor="terciaria"
                  visual="contorno"
                  onClick={() => {
                    resetForm();
                    fechar();
                  }}
                >
                  Cancelar
                </BotaoComTexto>
              </div>
              <button
                className="dialogo__botao-fechar"
                type="button"
                onClick={fechar}
                aria-label="Fechar diálogo"
              >
                <i className="far fa-times" aria-label="hidden" />
              </button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
