/* eslint-disable react/function-component-definition */
import { ComponentType, lazy, Suspense, useCallback, useMemo } from 'react';

import { AnimatePresence as AnimatePresenceOriginal } from 'framer-motion';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import type { ContextProps, RouteConfigItem, RouteProps } from 'models';
import { getNormalizedRoutes } from 'utils';
import { AppLayout } from 'layouts';

import { ProtectedPageWrapper, RouteContext } from './components';
import { RouterCreatorProps } from './types';

const AnimatePresence: any = AnimatePresenceOriginal;

export function RouterCreator({
  redirectPath,
  routesConfig,
  CustomContext,
  permissionValidator,
  fallbackRedirectPath = '/',
  routerAnimationType = 'leftToRight',
  overflowHiddenMotionWrapper = false,
}: RouterCreatorProps): JSX.Element {
  const location = useLocation();

  const memoPrivateRoutes = useMemo(() => {
    const normalizedRoutes = getNormalizedRoutes({
      routes: routesConfig,
      removeNoRenderRoutes: true,
      filter: {
        isPrivate: true,
        permissionValidator,
      },
    });

    return normalizedRoutes;
  }, [routesConfig, permissionValidator]);

  const memoPublicRoutes = useMemo(() => {
    const normalizedRoutes = getNormalizedRoutes({
      routes: routesConfig,
      removeNoRenderRoutes: true,
      filter: {
        isPrivate: false,
        permissionValidator,
      },
    });

    return normalizedRoutes;
  }, [permissionValidator, routesConfig]);

  const importComponent = useCallback((elementPath: string) => {
    return lazy<ComponentType<RouteProps>>(
      () => import(`../pages/${elementPath}` as const),
    );
  }, []);

  const RenderElement = useCallback(
    ({ route }: { route: RouteConfigItem }) => {
      const Element = route?.elementPath
        ? importComponent(route.elementPath)
        : route.element;
      const routeProps: RouteProps = {
        name: route?.name,
        routeTitle: route?.routeTitle,
        path: route.path,
      };

      return (
        <Suspense fallback={<></>}>
          <RouteContext route={route}>
            {Element && (
              <ProtectedPageWrapper
                animationType={routerAnimationType}
                overflowHiddenMotionWrapper={overflowHiddenMotionWrapper}
              >
                <Element {...routeProps} />
              </ProtectedPageWrapper>
            )}
          </RouteContext>
        </Suspense>
      );
    },
    [importComponent, overflowHiddenMotionWrapper, routerAnimationType],
  );

  const CustomContextProvider = useCallback(
    ({ children }: ContextProps) => {
      if (!CustomContext) {
        return <>{children}</>;
      }

      return <CustomContext>{children}</CustomContext>;
    },
    [CustomContext],
  );

  return (
    <CustomContextProvider>
      <AnimatePresence initial exitBeforeEnter>
        <Routes location={location} key={location.pathname}>
          {memoPublicRoutes.map(route => (
            <Route
              key={route.id}
              path={route.path}
              element={<RenderElement route={route} />}
            />
          ))}
          <Route element={<AppLayout />}>
            {memoPrivateRoutes.map(route => (
              <Route
                key={route.id}
                path={route.path}
                element={<RenderElement route={route} />}
              />
            ))}
          </Route>
          <Route
            path="*"
            element={<Navigate to={fallbackRedirectPath || redirectPath} />}
          />
        </Routes>
      </AnimatePresence>
    </CustomContextProvider>
  );
}
