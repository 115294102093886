import { BrowserRouter } from 'react-router-dom';

import { AppContext } from 'contexts';
import { AuthProvider, useClarity } from 'hooks';

function App(): JSX.Element {
  console.info(`Versão: ${process.env.REACT_APP_VERSION}`);
  useClarity();

  return (
    <BrowserRouter>
      <AuthProvider>
        <AppContext />
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;
