import {
  CriarMensagemParams,
  GerenciadorDeMensagem,
} from '@digix-ui/componentes';

export function useGerenciadorDeMensagens(): (
  params: CriarMensagemParams,
) => void {
  function criarMensagem(params: CriarMensagemParams): void {
    GerenciadorDeMensagem.criarMensagem({ ...params, tempoDeExibicao: 5000 });
  }

  return criarMensagem;
}
